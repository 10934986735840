import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import styles from './newsletter.module.scss'
import LogoSmall from './svg/LogoSmall.svg'
import { VALID_EMAIL_REGEXP } from '../lib/constants'
import { useTranslation } from 'next-i18next'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

const Newsletter = (props) => {
  const { isMobile, small, centered } = props
  const { t, i18n } = useTranslation()
  const [subscription, setSubscription] = useState({
    email: '',
    accepted: false,
    subscribed: false,
  })

  const url =
    'https://wellnesstraveller.us17.list-manage.com/subscribe/post?u=86cbcf0c7be23aa98674e126e&amp;id=fab2def08e'

  const CustomForm = ({ status, message, onValidated }) => {
    let email
    let accepted

    const submit = () => {
      email &&
        accepted &&
        email.value.indexOf('@') > -1 &&
        onValidated({
          EMAIL: email.value,
        })
    }

    return (
      <div>
        {status && status === 'success' && (
          <div
            style={{ color: '#7BCAD2' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status && status === 'error' && (
          <div
            style={{ color: '#EC6C68' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {!status && (
          <>
            <div className={styles.input}>
              <input
                type="email"
                placeholder="Your email address"
                ref={(node) => (email = node)}
              />
              <button type="submit" id="newsletter-button" onClick={submit} className={'cta-event-trigger'}>
                Submit
              </button>
            </div>
            <div>
              <input
                type="checkbox"
                ref={(node) => (accepted = node)}
                value={'y'}
              />{' '}
              <label>
                I agree to the terms and understand the privacy statement
              </label>
            </div>
          </>
        )}
      </div>
    )
  }
  const renderForm = ({ subscribe, status, message }) => (
    <CustomForm
      onValidated={(formData) => subscribe(formData)}
      status={status}
      message={message}
    />
  )

  const subscribe = () => {
    if (
      VALID_EMAIL_REGEXP.test(subscription.email) &&
      subscription.accepted === true
    ) {
      setSubscription({ ...subscription, subscribed: true })
    }
  }
  // if (isMobile) {
  //   return <></>
  // }
  return (
    <div className={`${styles.wrapper} ${small ? styles.noBorder : ''} ${centered ? 'justify-center' : ''}`}>
      {!small && (
        <div className={styles.logo}>
          <LogoSmall />
        </div>
      )}
      
      <div className={styles.newsletter}>
        <h3>{t('wellness-newsletter')}</h3>
        <MailchimpSubscribe url={url} render={renderForm} />
        {/*{!subscription.subscribed && (*/}
        {/*  <div>*/}
        {/*    <div className={styles.input}>*/}
        {/*      <input*/}
        {/*        type="email"*/}
        {/*        placeholder={'Your best email address'}*/}
        {/*        defaultValue={subscription.email}*/}
        {/*        onChange={(evt) => {*/}
        {/*          if (VALID_EMAIL_REGEXP.test(evt.target.value)) {*/}
        {/*            setSubscription({*/}
        {/*              ...subscription,*/}
        {/*              email: evt.target.value,*/}
        {/*            })*/}
        {/*          } else {*/}
        {/*            console.log('EMAIL IS INVALID')*/}
        {/*          }*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <button type="submit" onClick={subscribe}>*/}
        {/*        Submit*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className={styles.acceptance}>*/}
        {/*      <input*/}
        {/*        type="checkbox"*/}
        {/*        defaultChecked={subscription.accepted}*/}
        {/*        onChange={(evt) => {*/}
        {/*          setSubscription({*/}
        {/*            ...subscription,*/}
        {/*            accepted: evt.target.checked,*/}
        {/*          })*/}
        {/*        }}*/}
        {/*      />{' '}*/}
        {/*      <label>*/}
        {/*        I agree to the terms and understand the privacy statement*/}
        {/*      </label>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{subscription.subscribed && (*/}
        {/*  <div className={styles.subscribed}>*/}
        {/*    <p>*/}
        {/*      <strong>Thank you for subscribing to our newsletter</strong>*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  )
}
Newsletter.propTypes = {
  isMobile: PropTypes.bool,
  small: PropTypes.bool,
  centered: PropTypes.bool
}
export default Newsletter
